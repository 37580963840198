<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <span class="kumbhBold" style="font-size: 18px"
          >ID Proofs (Documents shall be added only in PDF format)</span
        >
      </v-flex>
      <v-flex xs12 pt-5>
       
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Adhar Card Number</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="mydocs.documentDetails.adharCardNum"
                  label="Adhar Card Number"
                  outlined
                  dense
                 
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Adhar Card File</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.adharCardFile">
           
            <v-layout wrap align-center>
              <v-flex xs12 >
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.adharCardFile">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View Adhar Card File
                  </v-btn>
                </a>
&nbsp;
                <v-btn
                color="#005f32"
                dark
                small
                @click="$refs.adharCardFile.click()"
                style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
              >
              <v-icon left small>
                mdi-pencil
              </v-icon>
                Edit Adhar Card File
              </v-btn>
              <input
                v-show="false"
                id="file"
                ref="adharCardFile"
                type="file"
                accept="application/pdf"
                @change="adharCardUpload($event)"
              />
              </v-flex>
            
               
            
            </v-layout>
          
            <v-flex xs12>
              <v-chip
                v-if="adharCardFile.name"
                class="ma-2"
                outlined
                color="#005f32"
                text-color="#929292"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span>{{ adharCardFile.name.slice(0, 40) }}
                      <span v-if="adharCardFile.name.length > 40">
                        ...
                      </span></span
                    >
                  </v-flex>
                </v-layout>
              </v-chip>
            </v-flex>
            
          </v-flex>
          

          <v-flex xs12 sm6 md8 text-left v-else>
          
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="adharCardFile.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ adharCardFile.name.slice(0, 40) }}
                        <span v-if="adharCardFile.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.adharCardFile.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD  Adhar Card File
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="adharCardFile"
                  type="file"
                  accept="application/pdf"
                  @change="adharCardUpload($event)"
                />
              </v-flex>
            </v-layout>
         
          </v-flex>
        </v-layout>
      
      <br>   <v-divider class="py-3"></v-divider> 
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Pan Card Number</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
         
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="mydocs.documentDetails.panCardNum"
                  label="Pan Card Number"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
         
          </v-flex>
        </v-layout>
        
        <v-layout wrap >

          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Pan Card File</span>
          </v-flex>

          <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.panCardFile">
           
            <v-layout wrap align-center>
             
              <v-flex xs12 >
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.panCardFile">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View Pan Card File
                  </v-btn>
                </a>
&nbsp;
                <v-btn
                color="#005f32"
                dark
                small
                @click="$refs.panCardFile.click()"
                style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
              >
              <v-icon left small>
                mdi-pencil
              </v-icon>
                Edit Pan Card File
              </v-btn>
              <input
                v-show="false"
                id="file"
                ref="panCardFile"
                type="file"
                accept="application/pdf"
                @change="panCardUpload($event)"
              />
              </v-flex>
            
               
            
            </v-layout>
          
            <v-flex xs12>
              <v-chip
                v-if="panCardFile.name"
                class="ma-2"
                outlined
                color="#005f32"
                text-color="#929292"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span>{{ panCardFile.name.slice(0, 40) }}
                      <span v-if="panCardFile.name.length > 40">
                        ...
                      </span></span
                    >
                  </v-flex>
                </v-layout>
              </v-chip>
            </v-flex>

            
          </v-flex>
      

          <v-flex xs12 sm6 md8 text-left v-else>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="panCardFile.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ panCardFile.name.slice(0, 40) }}
                        <span v-if="panCardFile.name.length > 40"> ... </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn color="#005f32" dark small @click="$refs.panCardFile.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD Pan Card File
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="panCardFile"
                  type="file"
                  accept="application/pdf"
                  @change="panCardUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <br>    <v-divider class="py-3"></v-divider> 
   
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Passport Or Licence Number </span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="mydocs.documentDetails.passportOrLicenceNum"
                  label="Passport Or Licence Number"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
         <v-layout wrap >
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Passport/Licence File</span>
          </v-flex>

          <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.passportOrLicenceFile">
            
            <v-layout wrap align-center>
              <v-flex xs12 >
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.passportOrLicenceFile">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View Pan Card File
                  </v-btn>
                </a>
&nbsp;
                <v-btn
                color="#005f32"
                dark
                small
                @click="$refs.passportOrLicenceFile.click()"
                style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
              >
              <v-icon left small>
                mdi-pencil
              </v-icon>
                Edit Passport/Licence File
              </v-btn>
              <input
                v-show="false"
                id="file"
                ref="passportOrLicenceFile"
                type="file"
                accept="application/pdf"
                @change="paasportOrlicenceUpload($event)"
              />
              </v-flex>
            
               
            
            </v-layout>
          
            <v-flex xs12>
              <v-chip
                v-if="passportOrLicenceFile.name"
                class="ma-2"
                outlined
                color="#005f32"
                text-color="#929292"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span>{{ passportOrLicenceFile.name.slice(0, 40) }}
                      <span v-if="passportOrLicenceFile.name.length > 40">
                        ...
                      </span></span
                    >
                  </v-flex>
                </v-layout>
              </v-chip>
            </v-flex>
          </v-flex>

          <v-flex xs12 sm6 md8 text-left v-else>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="passportOrLicenceFile.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ passportOrLicenceFile.name.slice(0, 40) }}
                        <span v-if="passportOrLicenceFile.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn color="#005f32" dark small @click="$refs.passportOrLicenceFile.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD Passport/Licence File
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="passportOrLicenceFile"
                  type="file"
                  accept="application/pdf"
                  @change="paasportOrlicenceUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-end py-5>
      <v-flex xs12 text-end>
        <v-btn color="#005f32" dark @click="validateForm()"
          ><span>Save Details</span></v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar : false,
      timeout: 5000,
      msg: null,
      adharCardFile: "",
      panCardFile: "",
      passportOrLicenceFile: "",
      adharCardNum: "",
      panCardNum: "",
      passportOrLicenceNum : "",
      mydocs:{},
      formData: new FormData(),
    };
  },
  mounted() {
    this.getMyUploads();
  },
  methods: {
    validateForm() {
      // if (!this.mydocs?.documentDetails?.adharCardNum || 
      //     !this.mydocs?.documentDetails?.panCardNum || 
      //     !this.mydocs?.documentDetails?.passportOrLicenceNum) {
      //   this.msg = "Please fill all required fields";
      //   this.showSnackBar = true;
      //   return false;
      // }

       // Validate that all required files are uploaded
     

      // Validate Aadhaar number (12 digits)
      if ( this.mydocs.documentDetails.adharCardNum && !/^\d{12}$/.test(this.mydocs.documentDetails.adharCardNum)) {
        this.msg = "Invalid Aadhaar number format";
        this.showSnackBar = true;
        return false;
      }
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (this.adharCardFile && this.adharCardFile.size > maxSize) {
        this.msg = "Aadhaar Card file size should be less than 5MB";
        this.showSnackBar = true;
        return false;
      }

      // Validate PAN number (ABCDE1234F format)
      if (this.mydocs.documentDetails.panCardNum && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(this.mydocs.documentDetails.panCardNum)) {
        this.msg = "Invalid PAN number format(ABCDE1234F)";
        this.showSnackBar = true;
        return false;
      }
      
      if (this.panCardFile && this.panCardFile.size > maxSize) {
        this.msg = "PAN Card file size should be less than 5MB";
        this.showSnackBar = true;
        return false;
      }

      // Validate Passport/License number (8-15 alphanumeric characters)
      if (this.mydocs.documentDetails.passportOrLicenceNum && !/^[A-Z0-9]{8,15}$/.test(this.mydocs.documentDetails.passportOrLicenceNum)) {
        this.msg = "Invalid Passport/License number format";
        this.showSnackBar = true;
        return false;
      }
      if (this.passportOrLicenceFile && this.passportOrLicenceFile.size > maxSize) {
        this.msg = "Passport/License file size should be less than 5MB";
        this.showSnackBar = true;
        return false;
      }
      else{
        this.add();
      }
    },


    getMyUploads() {
      this.appLoading = true;
      axios({
        url: "/employee/idproof/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: localStorage.getItem("userid"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.mydocs = response.data.data;
          } else {
            this.showsnackbar = false;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    adharCardUpload(event) {
      const file = event.target.files[0];
      if (file.type !== 'application/pdf') {
        this.msg = "Please upload PDF files only";
        this.showSnackBar = true;
        return;
      }
      this.adharCardFile = file;
    },
    panCardUpload(event) {
      const file = event.target.files[0];
      if (file.type !== 'application/pdf') {
        this.msg = "Please upload PDF files only";
        this.showSnackBar = true;
        return;
      }
      this.panCardFile = file;
    },
    paasportOrlicenceUpload(event) {
      const file = event.target.files[0];
      if (file.type !== 'application/pdf') {
        this.msg = "Please upload PDF files only";
        this.showSnackBar = true;
        return;
      }
      this.passportOrLicenceFile = file;
    },

    add() {
      let formData = new FormData();
      formData.append("id", localStorage.getItem("userid"));
      formData.append("adharCardFile", this.adharCardFile);
      formData.append("adharCardNum", this.mydocs.documentDetails.adharCardNum);
      formData.append("panCardFile", this.panCardFile);
      formData.append("panCardNum", this.mydocs.documentDetails.panCardNum);
      formData.append("passportOrLicenceFile", this.passportOrLicenceFile);
      formData.append("passportOrLicenceNum", this.mydocs.documentDetails.passportOrLicenceNum);

      axios({
        method: "POST",
        url: "/employee/idproof/upload",
        data: formData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            
            this.adharCardFile = "";
            this.panCardFile = "";
            this.passportOrLicenceFile = "";

            this.appLoading = false;
            this.getMyUploads();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    }
  },
};
</script>