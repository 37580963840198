<template>
    <div>
        <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
      
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <span class="kumbhBold" style="font-size: 18px"
            >Legal Details (Documents shall be added only in PDF format)</span
          >
        </v-flex>
        <v-flex xs12 pt-5>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Contract Letter</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.contractLetter">
              <v-card outlined class="pa-3">
              <v-layout wrap>
              

       
                <v-flex xs12>
                  <a target="_blank" :href="mediaURL + mydocs.documentDetails.contractLetter">
                    <v-btn
                      style="border-radius: 14px; border: 0.5px solid #707070"
                      px-5
                      pt-0
                      text
                      small
                      outlined
                    >
                      View Contract Letter
                    </v-btn>
                  </a>
                  &nbsp;  &nbsp;
                  <v-btn
                    color="#005f32"
                    dark
                    small
                    @click="$refs.contractLetter.click()"
                    style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
                  >
                  <v-icon left small>
                    mdi-pencil
                  </v-icon>Edit Contract Letter
                  </v-btn>
                  <input
                    v-show="false"
                    id="file"
                    ref="contractLetter"
                    type="file"
                    accept="application/pdf"
                    @change="contractLetterUpload($event)"
                  />
                </v-flex>
            
              </v-layout>
            </v-card>
              <v-flex xs12>
                <v-chip
                  v-if="contractLetter.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span>{{ contractLetter.name.slice(0, 40) }}
                        <span v-if="contractLetter.name.length > 40">
                          ...
                        </span></span
                      >
                    </v-flex>
                  </v-layout>
                </v-chip>
              </v-flex>
            </v-flex>
            

            <v-flex xs12 sm6 md8 text-left v-else>
              <v-card outlined class="pa-3">
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="contractLetter.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ contractLetter.name.slice(0, 40) }}
                          <span v-if="contractLetter.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn
                    color="#005f32"
                    dark
                    small
                    @click="$refs.contractLetter.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD contract Letter
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="contractLetter"
                    type="file"
                    accept="application/pdf"
                    @change="contractLetterUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-card>
            </v-flex>
          </v-layout>
          
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Offer Letter </span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.offerLetter">
              <v-card outlined class="pa-3">
              <v-layout wrap>
                <v-flex xs12>
                  <a target="_blank" :href="mediaURL + mydocs.documentDetails.offerLetter">
                    <v-btn
                      style="border-radius: 14px; border: 0.5px solid #707070"
                      px-5
                      pt-0
                      text
                      small
                      outlined
                    >
                      View Offer Letter
                    </v-btn>
                  </a>
                  &nbsp;  &nbsp;
                  <v-btn
                    color="#005f32"
                    dark
                    small
                    @click="$refs.offerLetter.click()"
                    style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
                  > 
                  <v-icon left small>
                    mdi-pencil
                  </v-icon>
                  EDIT Offer Letter
                  </v-btn>
                  <input
                    v-show="false"
                    id="file"
                    ref="offerLetter"
                    type="file"
                    accept="application/pdf"
                    @change="offerLetterUpload($event)"
                  />
                </v-flex>
              </v-layout>
              </v-card>
              <v-flex xs12>
                <v-chip
                  v-if="offerLetter.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span>{{ offerLetter.name.slice(0, 40) }}
                        <span v-if="offerLetter.name.length > 40">
                          ...
                        </span></span
                      >
                    </v-flex>
                  </v-layout>
                </v-chip>
              </v-flex>
            </v-flex>


            <v-flex xs12 sm6 md8 text-left v-else>
              <v-card outlined class="pa-3">
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="offerLetter.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ offerLetter.name.slice(0, 40) }}
                          <span v-if="offerLetter.name.length > 40"> ... </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.offerLetter.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD Offer Letter
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="offerLetter"
                    type="file"
                    accept="application/pdf"
                    @change="offerLetterUpload($event)"
                  />
                </v-flex>
              </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        
        </v-flex>
      </v-layout>
      <v-layout wrap justify-end pt-6>
        <v-flex xs12 text-end>
          <v-btn color="#005f32" dark @click="validateAndAdd()"
            ><span>Save Details</span></v-btn
          >
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        showSnackBar: false,
        timeout: 5000,
        ServerError: false,
        msg: null,
        contractLetter: "",
        offerLetter: "",
        paasportOrlicence: "",
        formData: new FormData(),
        mydocs:{},
      };
    },
    mounted() {
    //this.getData();
    this.getMyUploads();
  },
  
    methods: {
        contractLetterUpload(event) {
        this.contractLetter = event.target.files[0];
      },
      offerLetterUpload(event) {
        this.offerLetter = event.target.files[0];
      },
      getMyUploads() {
      this.appLoading = true;
      axios({
        url: "/legalDeatils/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          //id: this.$route.query.id,
          id: localStorage.getItem("userid"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.mydocs = response.data.data;
          } else {
            this.showsnackbar = false;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },

    validateForm() {
     

    
      // Validate that all required files are uploaded
      // if (!this.contractLetter && !this.mydocs?.documentDetails?.contractLetter) {
      //   this.msg = "Please upload contract letter";
      //   this.showSnackBar = true;
      //   return false;
      // }

      // if (!this.offerLetter && !this.mydocs?.documentDetails?.offerLetter) {
      //   this.msg = "Please upload offer letter";
      //   this.showSnackBar = true;
      //   return false;
      // }

    

      // Validate file sizes (max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (this.contractLetter && this.contractLetter.size > maxSize) {
        this.msg = "Contract letter size should be less than 5MB";
        this.showSnackBar = true;
        return false;
      }

      if (this.offerLetter && this.offerLetter.size > maxSize) {
        this.msg = "Offer letter file size should be less than 5MB";
        this.showSnackBar = true;
        return false;
      }

     

      return true;
    },

    validateAndAdd() {
      if (this.validateForm()) {
        this.add();
      }
    },

    
      add() {
        let formData = new FormData();
        formData.append("id", localStorage.getItem("userid"));
        formData.append("contractLetter", this.contractLetter);
        formData.append("offerLetter", this.offerLetter);
      
        axios({
          method: "POST",
          url: "/employee/legaldocuments/upload",
          data: formData,
          headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
              this.contractLetter = "";
              this.offerLetter = "";
              this.appLoading = false;
              this.getMyUploads();
              // this.$emit("stepper", {
              //   ref: "page1Tab",
              //   level: "tab-7",
              // });
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
    